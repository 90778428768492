.surveyTotal__row {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
}

.surveyTotal__row.total {
    border-top: 1px solid #202020;
    padding-top: 1rem;
}

.surveyTotal__container {
    margin-top: 2rem;
}

.surveyDoors__container p {
    text-align: center;
    font-weight: 500;
}

.surveyDoor__container {
    margin-bottom: 1rem;
    font-weight: 500;
    display: flex;
}