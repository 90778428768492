.floorPin {
    background-color: white;
    color: #000;
    padding: 0.5rem;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    z-index: 1000;
    border: 1px solid #ccc;
}

.floorPin--transparent {
    background-color: white;
    padding: 3px;
    position: absolute;
    text-align: center;
}

.floorPin button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.floorPin button .edit {
    fill: white;
}

.popup--pin {
    background-color: white;
    position: absolute;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    top: 50%;
    left: 50%;
    transform: translate(-150px, -50px);
    min-width: 300px;
    padding: 1rem;
}

.popup__header {
    display: flex;
    justify-content: flex-end;
}

.popup__header .close {
    fill: #aaa;
}

.popup__content {
    display: flex;
    justify-content: space-between;
    max-width: 100px;
    margin: 0 auto;
}