.popup {
    background-color: white;
    padding: 2rem 1rem;
    position: absolute;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    top: 50%;
    left: 50%;
    transform: translate(-150px, -50px);
    max-width: 300px;
    width: 100%;
    border: 1px solid #ccc;
}

.popup .title {
    color: #333;
    display: block;
    text-align: center;
}

.popup input {
    margin-top: 1rem;
    width: 100%;
}

.popup__content {
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
}

.popup button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0.75rem 1rem;
}

.popup button.save {
    background-color: #cc2020;
    color: #fff;
}

.popup--edit {
    max-width: 225px !important;
}