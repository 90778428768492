.container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #f7f7f7;
    top: 0;
    left: 0;
    z-index: 10000;
    padding: 10px;
    box-sizing: content-box;
    overflow-x: scroll;
}

.pin {
    position: absolute;
    display: block;
    height: 24px;
    width: 24px;
    background-color: transparent;
}

.pin svg {
    fill: #cc2020;
}

.pin.active svg {
    fill: #d3a045;
}

.floorPlan__header {
    display: flex;
    justify-content: flex-end;
    padding: 0 12px;
}

.floorPlan__header button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
}

.floorPlan__container {
    display: flex;
    position: relative;
    width: 85%;
    border: 3px solid black;
}

.floorPlan__main {
    position: relative;
}

.floorPlan__tools {
    position: fixed;
    right: 0;
    top: 50px;
    display: flex;
    flex-direction: column;
}

.floorPlan__tools button {
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 0.625rem;
}

.floorPlan__tools button:hover,
.floorPlan__tools button:focus {
    outline: none;
}

.floorPlan__plan {
    width: 100%;
    justify-content: center;
    display: flex;
}

.floorPlan__image {
    /* width: 100%; */
    /* max-height: 400px;
    -o-object-fit: cover;
    object-fit: cover; */
    vertical-align: middle;
}

svg.disabled {
    fill: #ccc;
}

svg.disabled.edit {
    opacity: 0.4;
}

.popup__row {
    display: flex;
    justify-content: center;
}

.popup--arrows {
    padding: 0 0 .5rem 0!important;
    transform: none;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.4) !important;
    max-width: 225px !important;
}