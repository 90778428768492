.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
  right: 0;
  position: relative;
  transition: 0.5s;
  background-color: #2d2d2d;
  color: #fff;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
}

.app.no-transition {
  transition: none !important;
}

.app.hideMenu {
  right: 0;
}

.app.openMenu {
  right: 350px;
}

a {
  color: #cc2020;
  text-decoration: none;
}

h1 {
  font-size: 1.125rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: bolder;
}

h2 {
  font-size: 1.125rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: bolder;
}

.content {
  max-width: 400px;
  width: 90%;
  margin: 0 auto;
  flex-grow: 1;
}

.page {
  background-color: #f3f3f3;
  padding: 1.25rem 1rem 7rem;
  box-sizing: content-box;
  flex-grow: 1;
  margin-top: 100px;
  color: #202020;
}

.page-content {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding: 1.25rem 1rem;
  padding-bottom: 7rem;
  overflow-y: auto;
}

.home {
  padding: 0 0.375rem;
}

.page--bgRed {
  background-color: #CC2020;
}

.text--uppercase {
  text-transform: uppercase;
}

.text--md {
  font-size: 1.125rem;
}

.text--lg {
  font-size: 1.25rem;
}

.text--xl {
  font-size: 1.5rem;
}

.text--2xl {
  font-size: 1.75rem;
}

.text--thin {
  font-weight: 400;
}

.text--gold {
  color: #FF1190;
}

.text-center {
  text-align: center;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.home__title {
  font-weight: 700;
  font-size: 1.125rem;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0.5rem;
}

.leading-none {
  line-height: 1;
}

.font-normal	{
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.block {
  display: block;
}

form {
  margin-top: 1rem;
}

.form__instructions {
  text-align: center;
  font-weight: 600;
  font-size: 0.75rem;
}

.form__title {
  background-color: #1D1D1D;
  color: #fff;
  padding: 0.75rem 1.25rem;
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.form-group {
  margin-top: 1rem;
}

.form-group--checkbox {
  display: block;
  position: relative;
  padding-left: 15px;
  margin-bottom: 12px;
  margin-top: 1rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form__label {
  display: block;
  margin-top: 1rem;
  padding-left: 1.25rem;
}

.form__label.clickable {
  cursor: pointer;
  background-color: #d3a045;
  padding: 1rem;
  text-align: center;
  border-radius: 4px;
  color: #fff;
  margin: 0 auto;
  display: block;
  width: 94%;
}

.form__label--inline {
  display: inline-block;
  margin-right: 0.5rem;
  padding-left: 1rem;
}

.form__input, .form__textarea {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  font-size: 1.125rem;
  padding: 0.5rem;
  margin-top: 0.25rem;
}

.form__checkbox {
  position: absolute;
  left: 0;
  top: 0;
}

.form__selectRow {
  display: flex;
  justify-content: space-between;
}

.form__selectRow > *:last-child {
  margin-right: 0;
}

.form__select {
  border-radius: 5px;
  border: none;
  padding: 0.5rem 0.75rem;
  flex: 1;
  margin-right: 1rem;
  margin-top: 0.5rem;
  width: 100%;
  text-overflow: ellipsis;
}

.form__select.alternative {
  width: 94%;
  display: block;
  margin: 0 auto;
}

.radioContainer {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.form__radio {
  padding: 1rem;
  background-color: #ddb56e;
  color: #fff;
  margin-bottom: 1rem;
  position: relative;
  border-radius: 4px;
  width: 96%;
  margin: 0 auto 1rem;
}

.form__radio label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  height: 100%;
  padding: 1rem;
  cursor: pointer;
}

.form__radio.checked {
  background-color: #d3a045;
}

.form__radio input[type="radio"] {
  visibility: hidden;
}

.form__buttons {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 2rem;
}

.form__button {
  background-color: #d3a045;
  border: none;
  color: #fff;
  -webkit-appearance: none;
  font-size: 1rem;
  display: inline-flex;
  padding: 0.625rem 2rem;
  cursor: pointer;
  font-weight: bold;
}

.form__button.secondary {
  background-color: #d1bd99;
}

.form__button--inline {
  display: flex;
  justify-content: center;
}

.form__button:hover {
  outline: none;
}

.error {
  color: #ff8811;
  margin-left: 1rem;
  margin-top: 0.5rem;
  font-size: 0.875rem;
}

.not-found {
  text-align: center;
  margin-top: 1.5rem;
}

::-webkit-scrollbar {
  width: 0px;
}

.selectContainer {
  margin-top: 0.5rem;
}

.selectContainer select {
  margin: 0;
}

.actionContainer {
  margin-top: 1rem;
  padding-left: 1rem;
}

.action {
  margin-right: 1rem;
  margin-left: 1rem;
}

.action svg {
  width: 24px;
  height: 24px;
}

.action.fail svg {
  fill: #f56565;
}

.action.pass svg {
  fill: #48bb78;
}

.action.advisory svg {
  fill: #3ba4e3;
}

.failed-cost {
  color: #f56565;
}

.advisory-cost {
  color: #3ba4e3;
}

.fail {
  color: #f56565;
}

.pass {
  color: #48bb78;
}

.advisory {
  color: #3ba4e3;
}

.repair_label {
  padding-right: 1rem;
  padding-left: 1rem;
  font-weight: 400;
}

.symbol {
  padding-right: 0.5rem;
  padding-left: 1rem;
}

.desktop {
  display: block;
}

.file__container {
  display: flex;
}

.thumbnail img {
  border: 3px solid #fff;
  width: 100px;
  height: auto;
}

.w-6 {
  width: 1.5rem;
}

.h-6 {
  height: 1.5rem;
}

.field-incompleted.show { 
  background-color: #e2d1b4;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
}

.field-incompleted.show .form_label {
  padding-left: 0;
}

.field-incompleted.show .actionContainer {
  padding-left: 0;
}

@media only screen and (max-width: 600px) {
  .app.openMenu {
    right: 60%;
  }

  .form__label {
    padding-left: 0;
  }

  .repair_label {
    padding-left: 0;
  }

  .symbol {
    padding-left: 0;
  }

  .actionContainer {
    padding-left: 0;
  }

  .desktop {
    display: none;
  }

  .form__button {
    width: 100%;
    text-align: center;
  }
}

.popup {
  background-color: white;
  padding: 2rem 1rem;
  position: fixed;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  top: 50%;
  left: 50%;
  transform: translate(-150px, -100px);
  max-width: 300px;
  width: 100%;
  border: 1px solid #ccc;
}

.popup .title {
  color: #333;
  display: block;
  text-align: center;
}

.popup input {
  margin-top: 1rem;
  width: 100%;
}

.popup__content {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}

.popup button {
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0.75rem 1rem;
}

.popup button.primary {
  background-color: #d3a045;
}

.popup button:first-of-type {
  margin-right: 1rem;
}

.popup button.save {
  background-color: #cc2020;
  color: #fff;
}

.popup--edit {
  max-width: 225px !important;
}

.text-red {
  color: #cc2020;
}

.question-start {
  margin-top: 2.5rem;
}

.input__container--inline {
  display: flex !important;
}

.input__container--inline label {
  margin-right: 1rem;
}

.input__container--inline > div {
  flex: 1;
}

.customQuestions__header {
  background-color: #5a5a5a;
  color: #fff;
  padding: 10px;
}