.surveySuccess__container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.surveySuccess__container svg {
    height: 192px;
    width: 192px;
    fill: #f56565;
}

.surveySuccess__container svg.check {
    fill: #48bb78;
}