.selectorRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

.selectorRow__image {
    flex: 1;
    flex-basis: 70%;
}

.imageContainer {
    box-sizing: content-box;
    cursor: pointer;
    width: 100px;
    height: 100px;
    background-color: #fff;
    display: flex;
    align-items: center;
    border: 2px solid transparent;
}

.imageContainer:hover {
    border-color: #000;
}

.imageContainer img {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.selectorLabel {
    text-align: center;
    margin-bottom: 1rem;
}

.selectorButton {
    appearance: none;
    border: none;
    background-color: transparent;
    border-radius: 8px;
    display: flex;
    align-items: center;
    color: #fff;
    padding: 0.25rem 0.5rem;
    outline: none;
    cursor: pointer;
    min-width: 120px;
}

.selectorButton span {
    padding-left: 5px;
}

.selectorButtons {
    display: flex;
    align-items: center;
}

.popup {
    background-color: white;
    padding: 2rem 1rem;
    position: fixed;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    top: 50%;
    left: 50%;
    transform: translate(-150px, -50px);
    max-width: 300px;
    width: 100%;
}

.popup--preview {
    max-width: 400px;
    padding: 3rem 1rem 1rem;
}

.popup .title {
    color: #333;
    display: block;
    text-align: center;
}

.popup input {
    margin-top: 1rem;
    width: 100%;
}

.popup__content {
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
}

.popup button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0.75rem 1rem;
}

.popup button.save {
    background-color: #cc2020;
    color: #fff;
}

.popupClose__container {
    position: absolute;
    top: 0px;
    right: 0px;
}

.popupPreview__container {

}

.popupPreview__container img {
    width: 100%;
}

